$(document).on('turbolinks:load', function () {
  $('button[data-target="#snapshot-modal"').on('click', function (e) {
    $('input[name="snapshot[name]"]').val("");
    $('input[name="snapshot[type]"]').val($(this).data("type"));
  })
  $('#snapshot-modal .submit').on("click", function(e) {
    $(this).text("送信中...");
    $(this).prop("disabled", true);
    var type = $('input[name="snapshot[type]"]').val();
    var querySelector = `#${type}Container svg`;
    var svg = document.querySelector(querySelector);
    var svgData = new XMLSerializer().serializeToString(svg);
    $.ajax({
      type: 'POST',
      url: "/snapshots",
      data: {
        snapshot: {
          html: svgData.toString(),
          page_id: $('input[name="snapshot[page_id]"]').val(),
          page_content_id: $('input[name="snapshot[page_content_id]"]').val(),
          image_width: $('input[name="snapshot[image_width]"]').val(),
          image_height: $('input[name="snapshot[image_height]"]').val(),
          name: $('input[name="snapshot[name]"]').val(),
          type: type,
          is_mobile: $('input[name="snapshot[is_mobile]"]').val(),
        }
      },
    })
    .fail(function () {
      $("#snapshot-modal .submit").text("送信");
      $("#snapshot-modal .submit").prop("disabled", false);
    })
    .done(function (data, text, xhr){
      $("#snapshot-modal .submit").text("送信");
      $("#snapshot-modal .submit").prop("disabled", false);
      $("#snapshot-modal .close").trigger("click");
      open(data, "_blank");
    });
  });

  $("#snapshot-add-modal .submit").on("click", function() {
    $.ajax({
      type: 'GET',
      url: `/snapshots/${$('#snapshot-add-modal [name="id"]').val()}.js`,
    })
    .fail(function(){
      console.log("Error");
    })
    .done(function() {
      $("#snapshot-add-modal .close").trigger("click");
    });
  });
});
