import viewHeatmap from "packs/heatmap_view";

$(document).on('turbolinks:load', function () {
  if( $('#readRateContainer').length !== 0 ) {
    var initData = JSON.parse($("#read-rate-init-data").val());
    var data = JSON.parse($("#read-rate-data").val());
    var width = initData["width"]; // グラフの幅
    var height = initData["height"]; // グラフの高さ
    var rows = initData["rows"];
    var cols = initData["cols"];

    viewHeatmap("#readRateContainer", width, height, cols, rows, data);
  };
});

