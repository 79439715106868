$(document).on('turbolinks:load', function () {
  if ($("#reportrange")) {
    var start = moment().subtract(6, 'days');
    var end = moment();
  
    if ($('#result').length !== 0) {
      start = moment($('input[name="forms_heatmap[from_date]"]').val());
      end = moment($('input[name="forms_heatmap[to_date]"]').val());
    }
  
    function cb(start, end) {
      $('#reportrange span').html(start.format('YYYY/MM/DD') + ' - ' + end.format('YYYY/MM/DD'));
      $('input[name="forms_heatmap[from_date]"]').val(start.format('YYYY-MM-DD'));
      $('input[name="forms_heatmap[to_date]"]').val(end.format('YYYY-MM-DD'));
    }
  
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        '今日': [moment(), moment()],
        '昨日': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '直近7日間': [moment().subtract(6, 'days'), moment()],
        '直近30日間': [moment().subtract(29, 'days'), moment()],
        '今月': [moment().startOf('month'), moment().endOf('month')],
        '先月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month')
          .endOf('month')
        ]
      },
      locale: {
        format: 'YYYY/MM/DD',
        applyLabel: '反映',
        cancelLabel: 'キャンセル',
        fromLabel: '開始日',
        toLabel: '終了日',
        weekLabel: 'W',
        customRangeLabel: '自分で指定',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: moment.localeData()._week.dow
      },
    }, cb);
  
    cb(start, end);
  }
});
