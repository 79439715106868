$(document).on('turbolinks:load', function () {
  // 日本語設定
  moment.locale("ja");

  // Side Menu Hide Show JS
  $(".burger-menu").on('click', function () {
      $(".burger-menu").toggleClass("toggle-menu");
      // $(".navbar-brand").toggleClass("navbar-logo");
      $(".sidemenu-area").toggleClass("sidemenu-toggle");
      $(".sidemenu").toggleClass("hide-nav-title");
      $(".main-content").toggleClass("hide-sidemenu");
  });

  // Burger menu click show toggle x class
  $(".burger-menu").on('click', function () {
      $(".burger-menu").toggleClass("x");
  });

  // Feather Icon Js
  feather.replace();

  // Tooltip JS
  $('[data-toggle="tooltip"]').tooltip();

  // Gallery Viewer JS
  var console = window.console || {
      log: function () {}
  };
  // var $images = $('.gallery-content');
  // var $toggles = $('.gallery-toggles');
  // var $buttons = $('.gallery-buttons');
  // var options = {
  //     // inline: true,
  //     url: 'data-original',
  //     ready: function (e) {
  //         console.log(e.type);
  //     },
  //     show: function (e) {
  //         console.log(e.type);
  //     },
  //     shown: function (e) {
  //         console.log(e.type);
  //     },
  //     hide: function (e) {
  //         console.log(e.type);
  //     },
  //     hidden: function (e) {
  //         console.log(e.type);
  //     },
  //     view: function (e) {
  //         console.log(e.type);
  //     },
  //     viewed: function (e) {
  //         console.log(e.type);
  //     }
  // };

  // function toggleButtons(mode) {
  //     if (/modal|inline|none/.test(mode)) {
  //         $buttons
  //             .find('button[data-enable]')
  //             .prop('disabled', true)
  //             .filter('[data-enable*="' + mode + '"]')
  //             .prop('disabled', false);
  //     }
  // }
  // $images.on({
  //     ready: function (e) {
  //         console.log(e.type);
  //     },
  //     show: function (e) {
  //         console.log(e.type);
  //     },
  //     shown: function (e) {
  //         console.log(e.type);
  //     },
  //     hide: function (e) {
  //         console.log(e.type);
  //     },
  //     hidden: function (e) {
  //         console.log(e.type);
  //     },
  //     view: function (e) {
  //         console.log(e.type);
  //     },
  //     viewed: function (e) {
  //         console.log(e.type);
  //     }
  // }).viewer(options);
  // toggleButtons(options.inline ? 'inline' : 'modal');
  // $toggles.on('change', 'input', function () {
  //     var $input = $(this);
  //     var name = $input.attr('name');
  //     options[name] = name === 'inline' ? $input.data('value') : $input.prop('checked');
  //     $images.viewer('destroy').viewer(options);
  //     toggleButtons(options.inline ? 'inline' : 'modal');
  // });
  // $buttons.on('click', 'button', function () {
  //     var data = $(this).data();
  //     var args = data.arguments || [];
  //     if (data.method) {
  //         if (data.target) {
  //             $images.viewer(data.method, $(data.target).val());
  //         } else {
  //             $images.viewer(data.method, args[0], args[1]);
  //         }
  //         switch (data.method) {
  //             case 'scaleX':
  //             case 'scaleY':
  //                 args[0] = -args[0];
  //                 break;
  //             case 'destroy':
  //                 toggleButtons('none');
  //                 break;
  //         }
  //     }
  // });

  // FAQ Accordion Js
  $('.accordion').find('.accordion-title').on('click', function () {
      // Adds Active Class
      $(this).toggleClass('active');
      // Expand or Collapse This Panel
      $(this).next().slideToggle('fast');
      // Hide The Other Panels
      $('.accordion-content').not($(this).next()).slideUp('fast');
      // Removes Active Class From Other Titles
      $('.accordion-title').not($(this)).removeClass('active');
  });

  $(".clickable-row").on("click", function() {
    window.location = $(this).data("href");
  });

  $('.regex-target').on("click", function (){
    var re = $(this).data("regex");
    var t = $(this).data("regex-input");
    var i = $(t).val();
    if (i.length == 0) {
      $(t).val(re);
    } else {
      $(t).val(i + "|" + re);
    };
  });

  function alertHtmlText(type, msg) {
    return `
    <div class="alert alert-${type}" role="alert">
      ${msg}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    `;
  }

  $("#search-condition-save-modal").on("ajax:success", function (){
    $("#search-condition-save-modal .close").trigger("click");
    $(".main-content").prepend(alertHtmlText("success", "保存しました"));
  });

  $("#filter-heatmap .form-check-input").on("change", function (){
    var id = $(this).val();
    $(`#${id}-section`).toggleClass("d-inline-block");
    $(`#${id}-section`).toggleClass("d-none");
    var w = "col-xl-5 col-lg-5 col-sm-12 d-inline-block";
    $("#filter-heatmap .form-check-input:checked").each(function(i, elm) {
      var id = $(elm).val();
      $(`#${id}-section`).attr('class', w);
    });
  });
});

// Preloader JS
$(window).on('turbolinks:load', function () {
  $('.preloader').fadeOut();
});
