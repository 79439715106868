// import d3 from 'd3';
const d3 = require('d3');

$(document).on('turbolinks:load', function () {
  if( $('#clickAreaContainer').length !== 0 ) {
    // 1.データの準備
    var initData = JSON.parse($("#click-area-init-data").val());
    var data = JSON.parse($("#click-area-data").val());
    var width = initData["width"]; // グラフの幅
    var height = initData["height"]; // グラフの高さ

    // 2. SVG領域の設定
    var svg = d3.select("#clickAreaContainer")
      .append("svg")
        .attr("width", width)
        .attr("height", height);
    
    svg.append("rect")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("opacity", 0.5)
      .attr("fill", "black");

    svg.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
        .attr("cy", function(d) { return d[1] * height; })
        .attr("cx", function(d) { return d[0] * width; })
        .attr("r", "10")
        .attr("opacity", 0.6)
        .style("fill", "red");

  };
});

