import viewHeatmap from "packs/heatmap_view";

$(document).on('turbolinks:load', function () {
  if( $('#heatmapContainer').length !== 0 ) {
    var initData = JSON.parse($("#heatmap-init-data").val());
    var data = JSON.parse($("#heatmap-data").val());
    var dataConentScores = JSON.parse($("#content_scores").val());
    var width = initData["width"]; // グラフの幅
    var height = initData["height"]; // グラフの高さ
    var rows = initData["rows"];
    var cols = initData["cols"];

    viewHeatmap("#heatmapContainer", width, height, cols, rows, data, dataConentScores);
  };
});

