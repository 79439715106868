export default function viewHeatmap(id, width, height, cols, rows, matrix, matrix2 = []){
  console.log(matrix);
  console.log(matrix2);
  const d3 = require('d3');

  function bottomTooltipPathG50(start, end) {
    return `M ${start} ${end} 
    L ${start + 70} ${end} 
    L ${start + 70} ${end + 36} 
    L ${start + 65} ${end + 28} 
    L ${start} ${end + 28} 
    L ${start} ${end}`;
  }
  
  function bottomTooltipPathL50(start, end) {
    return `M ${start} ${end} 
    L ${start + 60} ${end} 
    L ${start + 60} ${end + 28} 
    L ${start + 6} ${end + 28} 
    L ${start} ${end + 32} 
    L ${start} ${end}`;
  }
  
  var svg = d3.select(id)
    .append("svg")
      .attr("width", width)
      .attr("height", height)
    .append("g")
      .attr("transform", "translate(0,0)");

  var x_scale = d3.scaleBand()
    .range([ 0, width ])
    .domain(d3.range(cols));

  var y_scale = d3.scaleBand()
    .range([ 0, height ])
    .domain(d3.range(rows));

  // Build color scale  
  var color = d3.scaleSequential()
    .interpolator( d3.interpolateSpectral )
    .domain([1, 0])

  const t = svg
    .selectAll(".row")
      .data(matrix)
      .enter()
      .append("g")
      .attr("class", "row")
      .attr("transform", function(d, i) { return "translate(0," + y_scale(i) + ")"; });

  t.append("rect")
    .attr("class", "cell")
    .attr("x", function(d, i) { return x_scale(i); })
    .attr("width", x_scale.bandwidth())
    .attr("height", y_scale.bandwidth())
    .attr("opacity", 0.6)
    .attr("fill", function(d) { return color(d); });

  t.append('path')
    .attr('d', function(d) { return `M 0 ${y_scale.bandwidth()} L ${x_scale.bandwidth() * d} ${y_scale.bandwidth()}` })
    .attr('stroke', 'gray')
    .attr('stroke-width', '2')
    .attr('fill', 'none')
    .attr('stroke-linecap', 'butt');

  t.append('path')
    .attr('d', function(d) { return `M ${x_scale.bandwidth() * d} ${y_scale.bandwidth()} L ${x_scale.bandwidth()} ${y_scale.bandwidth()}` })
    .attr('stroke', 'gray')
    .attr('stroke-width', '2')
    .attr('stroke-dasharray', '5,5')
    .attr('fill', 'none')
    .attr('stroke-linecap', 'butt');

  const u = t.append("g");

  u.append("path")
    .attr('d', function(d) { 
      if (d > 0.5) {
        return bottomTooltipPathG50(x_scale.bandwidth() * d - 70, y_scale.bandwidth() - 36); 
      } else {
        return bottomTooltipPathL50(x_scale.bandwidth() * d + 4, y_scale.bandwidth() - 36); 
      }
    })
    .attr('fill', 'black')
    .attr('fill-opacity', 0.6)
  u.append("text")
    .attr("y", y_scale.bandwidth() - 24)
    .style("fill", "white")
    .style("font-size", "13px")
    .html(function(d, i) { 
      let x = 0;
      if (d > 0.5) {
        x = x_scale.bandwidth()* d - 66;
      } else {
        x = x_scale.bandwidth()* d + 6;
      }
      let f = "";
      if (matrix2.length == 0) {
        if (i !== 0) {
          f = `${((matrix[i] - matrix[i-1]) * 100).toPrecision(4)}%`;
        } else {
          f = "0%";
        }  
      } else {
        f = `CS: ${(matrix2[i]).toPrecision(2)}`;
      }
      return (d !== null ? `<tspan x='${x}' dy='0em'>${(d * 100).toPrecision(4)}%</tspan><tspan x='${x}' dy='1em'>${f}</tspan>` : ""); 
    });
}

